// Adjusted code with updated messages based on content

import React, { useEffect, useRef, useState } from "react";
import Person2Icon from "@mui/icons-material/Person2";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from "@mui/material";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ChatInput from "./ChatInput";
import ChatSessions from "./ChatSessions";
import ChatIcon from "@mui/icons-material/Chat";
import { exchange } from "../common/ApiUtils";
import jwtDecode from "jwt-decode";
import { green, pink } from "@mui/material/colors";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { API_BASE_URL } from "../App";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import IconButton from "@mui/material/IconButton";
import { toast } from "react-toastify";


export default function MyChatMessages(props) {
  const [chatMessages, setChatMessages] = useState([]);
  const [sessionid, setSessionId] = useState("");
  const [sessionname, setSessionName] = useState("New Chat");
  const [chatsessions, setChatSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatSessionSelectedIndex, setchatSessionSelectedIndex] = useState(-1);
  const [streamingData, setStreamingData] = useState("");
  const [streaming, setStreaming] = useState(false);
  const [waitingforllm, setWaitingForLlm] = useState(false);
  const [inputDisabled, setinputDisabled] = useState(false);
  const scrollRef = useRef(null);
  let jwtToken = localStorage.getItem("genai-app-idtoken");
  let decodedToken = jwtDecode(jwtToken, { complete: true });
  const username = decodedToken.email;
  const [chatloading, setChatloading] = useState(false);
  const [syncAgentResponse, setSyncAgentResponse] = React.useState({});
  const [syncAgentMetadataResponse, setSyncAgentMetadataResponse] =
    React.useState({});
  const [syncingFailed, setASyncResponseFailed] = React.useState(null);
  const [syncing, setASyncResponse] = React.useState(null);
  const [syncingSucceeded, setASyncResponseSucceeded] = React.useState(null);
  const [selectedIconcloud, setSelectedIconcloud] = useState("AWS")
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [action, setAction] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [messageisDeleted, setmessageisDeleted] = useState('')


  useEffect(() => {
    if (messageisDeleted != "" && messageisDeleted == sessionid) {
      newChatSession()
      setmessageisDeleted("")
      setSessionId("")
    }

  }, [messageisDeleted])

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevChatMessages = usePrevious(chatMessages);

  useEffect(() => {
    mountFunction();
  }, []);

  useEffect(() => {
    if (scrollRef.current) {
      if (
        prevChatMessages &&
        chatMessages &&
        prevChatMessages.length != chatMessages.length
      )
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatMessages]);

  async function mountFunction() {
    newChatSession();
    refreshChatSessionComponent();
  }

  const handleCancelDelete = () => {
    setOpenDialog(false); 
  };
  const handleConfirmDelete = () => {
    delete_Individual_chat(selectedChat, action); // Call your delete function
    setOpenDialog(false);
    setAnchorEl(false) // Close the dialog
  };
  const handleDeleteClick = (index,action,item) => {
    setSelectedChat(index);
    setAction(action);
    setOpenDialog(true); 
     // Open the confirmation dialog
  };

  const delete_Individual_chat = (index, action, item) => {
    // Use the index as chatid
    const chatid = index;  // Use index as the unique chat id
    
    let message = {status:action};
    console.log(JSON.stringify(message));
    setLoading(true)

    // Make the API call with chatid (using index)
    exchange(
      "/api/chathistory/" + username + "/" + sessionid + "/" + chatid,  // Use index as chatid
      "POST",
      { messages: message }
    )
      .then((data) => {
        console.log(data);
        if (data.status === 200) {
          let messages = [...chatMessages];
          let msgToModify = { ...messages[index] };
          msgToModify.status = action;
          messages[index] = msgToModify;
          setChatMessages(messages);
          // If action is 'Deleted', check if all messages are deleted
          if (action === "Deleted") {
            onChatSessionListItemClick({ sessionid, sessionname });
            let deleted_message = messages.filter(message => message.status == "Deleted")
            let assistant_messages = messages.filter(message => message.role == "assistant")
            console.log("deleted message:", deleted_message);
            toast.success("Successfully deleted session")
            // console.log("all messages:", messages);
            // If all messages are deleted, delete the session
            if (deleted_message.length === assistant_messages.length) {
              delete_Session(username,sessionid);
            }
          }
          setLoading(false)
        } else {
          console.error("Failed to Delete the session !!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
};

const delete_Session = (userid, sessionid) => {
  exchange(`/api/chathistory/${userid}/${sessionid}`, "DELETE")
    // Use DELETE method
    .then((response) => {
      if (response.statusCode == 200) {
        return response;
      }
      else {
        throw new Error(`Failed to delete session: ${response.statusText}`);
      }
    })
    .then((data) => {
      console.log("Successfully deleted session:", data);
      refreshChatSessionComponent()
      setmessageisDeleted(sessionid)
      // setmessageisDeleted(true)
      // Optionally, refresh the session list here
    })
    .catch((error) => {
      console.error("Error deleting session:", error);
    });
};




  const updateChatSession = (message) => {
    console.log(JSON.stringify(message));
    exchange(
      "/api/chathistory/" + username + "/" + sessionid + "/chats",
      "POST",
      { messages: message }
    )
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const refreshChatSessionComponent = () => {
    setChatloading(true);

    exchange(
      "/api/chathistory/" +
      username +
      "?searchType=Agent&fields=sessionname,sessionid,updatedon",
      "GET",
      null
    )
      .then((data) => {
        let sessions = data.sort((a, b) => b.updatedon - a.updatedon);
        setChatSessions(sessions);
        setChatloading(false);
        console.log(data);
      })
      .catch((error) => console.log(error));
  };
  //date&Time
  const Date_time = () => {
    const now = new Date();
    const localeString = now.toLocaleString([], {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    const [date, time] = localeString.split(", ");
    return { date, time };
  };

  useEffect(() => {
    let citations = [];
    console.log("setAgentMetadataResponse", syncAgentMetadataResponse);
    const dateTime = Date_time();
    const dateTimeString = dateTime.date + "  " + dateTime.time;
    if (Array.isArray(syncAgentMetadataResponse)) {
      console.log("Processing metadata response");
      syncAgentMetadataResponse.forEach((metadataItem, index) => {
        if (Array.isArray(metadataItem)) {
          // metadataItem is an array (e.g., Array(1), Array(1))
          metadataItem.forEach(nestedArray => {
            if (Array.isArray(nestedArray)) {
              // Iterate over the items in the nested array
              nestedArray.forEach(item => {
                if (typeof item === "object" && item !== null) {
                  let fileUri = item?.metadata?.location?.s3Location?.uri;
                  let citation = {
                    pdf: "",
                    ppt: "",
                  };

                  if (fileUri) {
                    if (fileUri.includes(".pdf")) {
                      citation.pdf = fileUri;
                    } else if (fileUri.includes(".pptx")) {
                      citation.ppt = fileUri;
                    }

                    // Push citation to the citations array
                    citations.push(citation);
                  }
                }
              });
            }
            else {
              const fileUri = nestedArray?.metadata?.location?.s3Location?.uri;
              let citation = {
                pdf: "",
                ppt: "",
              };

              if (fileUri) {
                if (fileUri.includes(".pdf")) {
                  citation.pdf = fileUri;
                } else if (fileUri.includes(".pptx")) {
                  citation.ppt = fileUri;
                }
                citations.push(citation);
              }
            }
          });
        }
      });

      // Debug the result
      console.log("Citations:", citations);

    }

    // Only proceed if we have a syncAgentResponse
    if (syncAgentResponse && syncAgentResponse.result) {
      const message = {
        role: "assistant",
        content: syncAgentResponse.result,
        citations: citations,
        cloud: selectedIconcloud,
        time: dateTimeString,
        docs: syncAgentResponse.docs
      };
      setChatMessages((messages) => [...messages, message]);
      updateChatSession(message); // Make sure updateChatSession is defined or passed as a prop
      setWaitingForLlm(false);

      if (sessionname === "New Chat") {
        refreshChatSessionComponent(); // Make sure this function is defined or passed as a prop
      }
    }
  }, [syncAgentMetadataResponse, syncAgentResponse]);

  const onChatInputSendButtonClick = (input) => {
    setWaitingForLlm(true);
    const dateTime = Date_time();
    const dateTimeString = dateTime.date + "  " + dateTime.time;
    let message = { role: "user", content: input, time: dateTimeString };
    updateChatSession(message);
    setChatMessages((messages) => [...messages, message]);
    let url = `/api/agent/sync/?prompt=${input}`;
    url = `${url}&setSubject=True&sessionid=${encodeURIComponent(
      sessionid
    )}&userid=${encodeURIComponent(username)}&searchType=Agent&cloud=${selectedIconcloud}`;


    exchange(url, "POST")
      .then((data) => {
        if (data.statusCode === 200) {
          initiateAgentAsyncResponseCheck(data.request_id);
        } else {
          setASyncResponseFailed(true);
        }
      })
      .catch((error) => {
        setASyncResponseFailed(true);
        console.error(error.message);
      });
  };

  const initiateAgentAsyncResponseCheck = (request_id) => {
    let attemptCount = 0;
    const maxRetries = 20;
    const intervalTime = 5000; // 5 milliseconds

    const getAgentASyncResponse = async () => {
      if (attemptCount < maxRetries) {
        setASyncResponse(true);
        attemptCount++;
        try {
          const response = await exchange(
            `${API_BASE_URL}/agent/sync/${request_id}`,
            "GET"
          );
          if (response.status === "IN-PROGRESS") {
            setASyncResponse(true);
            setTimeout(getAgentASyncResponse, intervalTime);
          } else {
            if (response.status === "COMPLETE") {
              console.log("response", response);
              setASyncResponseSucceeded(true);
              console.log("setSyncAgentResponse>>>>>>>>>>>1:", response.result);
              setSyncAgentResponse(response);

              // Handle metadata as an array
              if (Array.isArray(response.metadata)) {
                setSyncAgentMetadataResponse(response.metadata);
              } else if (response.metadata) {
                // If it's not an array but exists, wrap it in an array
                setSyncAgentMetadataResponse([response.metadata]);
              } else {
                // If there's no metadata, set an empty array
                setSyncAgentMetadataResponse([]);
              }
            } else {
              setASyncResponseFailed(true);
            }
            setASyncResponse(false);
          }
        } catch (error) {
          console.error("Error in sync status check:", error);
          if (attemptCount >= maxRetries) {
            setASyncResponseFailed(true);
            setASyncResponse(false);
          } else {
            setTimeout(getAgentASyncResponse, intervalTime);
          }
        }
      } else {
        setASyncResponse(false);
        setASyncResponseFailed(true);
      }
    };

    getAgentASyncResponse();
  };

  const onChatSessionListItemClick = (item, index) => {
    setchatSessionSelectedIndex(index);
    // setMetadata([]);
    setChatMessages([]);
    setSessionId(item.sessionid + "");
    setSessionName(item.sessionname);
    setLoading(true);
    exchange("/api/chathistory/" + username + "/" + item.sessionid, "GET")
      .then((data) => {
        setChatMessages((messages) => [...messages, ...data.messages]);
        setLoading(false);
        let listBottom = document.getElementById("listBottom");
        listBottom.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
        console.log("data", data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const newChatSession = () => {
    setChatMessages([]);
    // setMetadata([]);
    setLoading(true);
    setchatSessionSelectedIndex(-1);
    setStreamingData("");
    exchange("/api/uuid", "GET")
      .then((data) => {
        setSessionId(data.sessionid);
        setSessionName("New Chat");
        setLoading(false);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const updateFeedback = (index, action) => {
    let message = { feedback: action };
    console.log(JSON.stringify(message));
    exchange(
      "/api/chathistory/" + username + "/" + sessionid + "/chats/" + index,
      "POST",
      { messages: message }
    )
      .then((data) => {
        console.log(data);
        if (data.status === 200) {
          let messages = [...chatMessages];
          let msgToModify = { ...messages[index] };
          msgToModify.feedback = action;
          messages[index] = msgToModify;
          setChatMessages(messages);
        } else {
          console.error("Failed to update feedback!!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const downloadDocument = (s3url) => {
    let url = `${API_BASE_URL}/documents`;
    let reqstBdy = { fileurl: s3url };
    exchange(url, "POST", reqstBdy)
      .then((dataResp) => {
        fetch(dataResp)
          .then((response) => {
            if (response.status === 200) {
              return response.blob();
            } else {
              throw new Error("Failed to fetch file");
            }
          })
          .then((content) => {
            var url = window.URL.createObjectURL(content);
            var a = document.createElement("a");
            a.href = url;
            var filename = s3url.substring(s3url.lastIndexOf("/") + 1);
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
          })
          .catch((error) => {
            console.error("Error fetching file:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  //file icon .ppt or .pdf
  const getIconByFileType = (fileType) => {
    switch (fileType) {
      case "pdf":
        return <PictureAsPdfIcon />;
      case "pptx":
        return (
          <Box
            component="img"
            sx={{ height: "53px", width: "fit-content", py: 2 }}
            alt="logo"
            src={require("../images/ppt.png")}
          />
        );
      default:
        return <InsertDriveFileIcon />;
    }
  };

  // Function to get the label based on the file type
  // const getLabelByFileType = (fileType) => {
  //   switch (fileType) {
  //     case 'pdf':
  //       return 'Download PDF';
  //     case 'pptx':
  //       return 'Download PPT';
  //     default:
  //       return 'Download File';
  //   }
  // };

  const tableStyles = `
    table {
      width: 100%;
      border-collapse: collapse;
    }
    th, td {
      border: 1px solid black;
      padding: 8px;
      text-align: left;
    }
  `;
  var displayMessages = "";
  displayMessages = (
    <Grid container height="90vh" style={{ overflow: "clip" }}>
      <Grid xs={3}>
        <Button
          variant="outlined"
          style={{ width: "90%", margin: "15px" }}
          endIcon={<ChatIcon />}
          onClick={newChatSession}
        >
          New Chat
        </Button>
        <div style={{ height: "70%", maxHeight: "80%", overflow: "auto" }}>
          <ChatSessions
            selectedIndex={chatSessionSelectedIndex}
            data={chatsessions}
            onChatSessionClick={onChatSessionListItemClick}
            loading={chatloading}
            setLoading={setLoading}
            username={username}
            refreshcomponent={refreshChatSessionComponent}
            setmessageisDeleted={setmessageisDeleted}

          />
        </div>
      </Grid>
      <Grid xs={9}>
        <Paper
          style={{
            height: "100%",
            maxHeight: "100%",
            overflow: "auto",
            margin: "1px 0px 0px 0px",
            backgroundColor: "#d6d6d6",
          }}
          elevation={3}
        >
          <Box height="75vh">
            <List
              style={{
                height: "100%",
                maxHeight: "100%",
                overflow: "auto",
                paddingLeft: "80px",
                paddingRight: "80px",
              }}
            >
              {chatMessages.map((chat, index, messages) => (
                !chat.isDeleted && (
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={
                      <React.Fragment>
                        {/* Avatar and user/genAI indication */}
                        <Stack direction="row" spacing={2}>
                          {chat.role === "user" ? (
                            <Avatar
                              sx={{
                                width: 24,
                                height: 24,
                                bgcolor: green[500],
                              }}
                            >
                              <Person2Icon />
                            </Avatar>
                          ) : (
                            <Avatar
                              sx={{ width: 24, height: 24, bgcolor: pink[500] }}
                            >
                              <SmartToyIcon />
                            </Avatar>
                          )}
                          {chat.role === "user" ? (
                            <span style={{ fontWeight: "bold" }}>You</span>
                          ) : (
                            <span style={{ fontWeight: "bold" }}>GenAI </span>
                          )}
                        </Stack>

                        {/* Chat content */}
                        <Stack direction="row" spacing={2}>
                          <div style={{ margin: "10px", marginBottom: "0px" }}>&nbsp;</div>
                          <Paper
                            elevation={2}
                            sx={{
                              backgroundColor: chat.role === "user" ? "lightblue" : "white",
                              padding: "5px",
                              paddingLeft: "15px",
                              paddingRight: "15px",
                              marginRight: "70px",
                              minWidth: chat.role == "assistant" && '80%'
                            }}
                          >
                            <div>
                              <style>{tableStyles}</style>
                              {chat.llm && chat.llm.startsWith("mistral") ? (
                                <div
                                  style={{
                                    overflowWrap: "break-word",
                                    whiteSpace: "pre-wrap", // Fixed "whitespace" to "whiteSpace"
                                    wordBreak: "keep-all",
                                    maxWidth: "100%",
                                  }}
                                >
                                  {chat.content}
                                </div>
                              ) : (
                                <div>
                                  <Markdown remarkPlugins={[remarkGfm]} style={{ minWidth: '90%' }}>{chat.content}</Markdown>
                                </div>
                              )}
                            </div>

                            {/* Timestamp Section */}
                            <pre
                              style={{
                                textAlign: "right",
                                color: "gray",
                                fontSize: "0.7em",
                                marginTop: "5px",
                              }}
                            >
                              {chat.time}
                            </pre>
                            {/* Citations/Docs Section */}
                            {chat?.docs && chat?.docs.length > 0 && (
                              <>
                                <hr
                                  style={{
                                    border: "none",
                                    borderTop: "1px solid #ccc",
                                    margin: "17px 0",
                                    width: "100%",  // Ensure it takes up full width
                                    transform: "none", // Remove any transforms
                                    boxSizing: "border-box" // Prevent padding from affecting layout
                                  }}
                                />
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  sx={{
                                    marginBottom: "10px",
                                    marginRight: "150px",
                                    width: "5px",
                                    fontSize: "9px", // Adjust as needed
                                  }}
                                >
                                  {chat.docs.map((citation, citationIndex) => (
                                    <div key={citationIndex}>
                                      <Chip
                                        color="success"
                                        variant="outlined"
                                        style={{
                                          color: "#5e5e5e",
                                          borderRadius: "0px",
                                          border: "1px solid",
                                        }}
                                        icon={<PictureAsPdfOutlinedIcon />}
                                        label={
                                          <div style={{ fontSize: "8px" }}>{citation}</div>
                                        }
                                      />
                                    </div>
                                  ))}
                                </Stack>
                              </>
                            )}
                          </Paper>
                        </Stack>




                        {/* Feedback icons */}
                        {chat.role === "user" ? null : (
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={{ mt: 1, ml: 1 }}
                          >
                            <div
                              style={{ margin: "10px", marginBottom: "0px" }}
                            >
                              &nbsp;
                            </div>
                            <ThumbUpIcon
                              style={{
                                color:
                                  chat.feedback === "liked" ? "blue" : "grey",
                                cursor: "pointer", marginLeft: '13px'
                              }}
                              onClick={() =>
                                updateFeedback(
                                  index,
                                  chat.feedback === "liked" ? null : "liked"
                                )
                              }
                            />
                            &nbsp;&nbsp;
                            <ThumbDownIcon
                              style={{
                                color:
                                  chat.feedback === "disliked"
                                    ? "darkred"
                                    : "grey",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                updateFeedback(
                                  index,
                                  chat.feedback === "disliked"
                                    ? null
                                    : "disliked"
                                )
                              }
                            />
                            {chat.role === "assistant" && (
                                  <div className="DeleteIcon"
                                  style={{
                                    marginTop:'-8px'
                               // Ensure it appears above content if overlapping
                                  }} >
                                    <IconButton  onClick={() => handleDeleteClick(index, chat.status="Deleted", chat)}>
                                      <DeleteOutlineOutlinedIcon />
                                    </IconButton>
                                  </div>
                                )}
                          </Stack>

                        )}
                        {/* {chat?.docs && chat.docs.length>0 &&
                        (
                          <Stack direction="row" style={{ marginLeft: '116px', marginTop: '-15px' }}>
                          <Tooltip
                            title={
                              <div className="icon">
                                {chat?.docs?.map((doc, index) => (
                                  <div key={index} style={{ padding: '5px 0', fontSize: '14px', color: 'white' }}>
                                    {doc}
                                  </div>
                                ))}
                              </div>
                            }
                            arrow
                            placement="top"
                          >
                            <PictureAsPdfOutlinedIcon
                              style={{
                                cursor: 'pointer',
                                color: '#5d5e5d',
                                fontSize: '23px',
                                marginTop:'-16px' 
                              }}
                            />
                          </Tooltip>
                        </Stack>

                        )} */}

                        <Grid container marginLeft={"8px"} marginTop={"8px"}>
                          {chat.citations &&
                            chat.citations.length > 0 &&
                            chat.citations
                              .slice(0, 3)
                              .map((citation, citationIndex) => {
                                return (
                                  <>
                                    {citation.pdf && (
                                      <Grid item>
                                        <Chip
                                          icon={getIconByFileType("pdf")}
                                          label={"Download PDF"}
                                          deleteIcon={<DownloadRoundedIcon />}
                                          onDelete={() =>
                                            downloadDocument(citation.pdf)
                                          }
                                          sx={{
                                            borderRadius: "7px",
                                            cursor: "pointer",
                                            backgroundColor: "whitesmoke",
                                            display: "flex",
                                            alignItems: "center",
                                            width: "140px",
                                            marginLeft: "30px",
                                            "& .MuiChip-deleteIcon": {
                                              "&:hover": {
                                                color: "#1976d2",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    )}
                                    {citation.ppt && (
                                      <Grid item>
                                        <Chip
                                          icon={getIconByFileType("pptx")}
                                          label={"Download PPT"}
                                          deleteIcon={<DownloadRoundedIcon />}
                                          onDelete={() =>
                                            downloadDocument(citation.ppt)
                                          }
                                          sx={{
                                            borderRadius: "7px",
                                            cursor: "pointer",
                                            backgroundColor: "whitesmoke",
                                            display: "flex",
                                            alignItems: "center",
                                            width: "140px",
                                            marginLeft: "30px",
                                            "& .MuiChip-deleteIcon": {
                                              "&:hover": {
                                                color: "#1976d2",
                                              },
                                            },
                                          }}
                                        />
                                      </Grid>
                                    )}
                                  </>
                                );
                              })}
                        </Grid>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                )
              ))}
              {waitingforllm == true ? (
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Stack direction="row" spacing={2}>
                          <Avatar
                            sx={{ width: 24, height: 24, bgcolor: pink[500] }}
                          >
                            <SmartToyIcon />
                          </Avatar>
                          <span style={{ fontWeight: "bold" }}>GenAI</span>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                          <div style={{ margin: "10px", marginBottom: "0px" }}>
                            &nbsp;
                          </div>
                          <Paper
                            elevation={2}
                            sx={{
                              backgroundColor: "white",
                              padding: "15px",
                              paddingLeft: "15px",
                              paddingRight: "15px",
                              marginRight: "70px",
                            }}
                          >
                            <>
                              <CircularProgress color="secondary" />
                            </>
                          </Paper>
                        </Stack>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ) : (
                ""
              )}
              {streaming == true ? (
                <ListItem alignItems="flex-start">
                  {/* <button onClick={downloadDocument()}> click</button> */}

                  <ListItemIcon>
                    <SmartToyIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Markdown>{streamingData}</Markdown>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ) : (
                ""
              )}
              <div id="listBottom" ref={scrollRef}></div>
            </List>

            <ChatInput
              onSendButtonClick={onChatInputSendButtonClick}
              disabled={loading}
              selectedPage="Agent"
              inputDisabled={inputDisabled}
              setinputDisabled={setinputDisabled}
              setSelectedIconcloud={setSelectedIconcloud}
              selectedIconcloud={selectedIconcloud}
            />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
  return (
    <>
    <div id="ByMyMessages">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {displayMessages}
    </div>
    <div>
    <Dialog open={openDialog} onClose={handleCancelDelete}>
    <DialogTitle>Confirm Deletion</DialogTitle>
    <DialogContent>
      <DialogContentText>Are you sure you want to delete this chat session? This action cannot be undone.</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCancelDelete} color="primary">
        Cancel
      </Button>
      <Button onClick={handleConfirmDelete} color="error" variant="contained">
        Delete
      </Button>
    </DialogActions>
  </Dialog>
  </div>
  </>
  );
}
