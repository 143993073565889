// Adjusted code with updated messages based on content
import React, { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Person2Icon from "@mui/icons-material/Person2";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Tooltip } from "@mui/material";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Typography,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from "@mui/material";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ChatInput from "./ChatInput";
import ChatSessions from "./ChatSessions";
import ChatIcon from "@mui/icons-material/Chat";
import { exchange } from "../common/ApiUtils";
import jwtDecode from "jwt-decode";
import { blue, green, pink } from "@mui/material/colors";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { API_BASE_URL } from "../App";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { toast } from "react-toastify";



export default function NonRagMessages(props) {
  const [chatMessages, setChatMessages] = useState([]);
  const [sessionid, setSessionId] = useState("");
  const [sessionname, setSessionName] = useState("New Chat");
  const [chatsessions, setChatSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatSessionSelectedIndex, setchatSessionSelectedIndex] = useState(-1);
  const [streamingData, setStreamingData] = useState("");
  const [streaming, setStreaming] = useState(false);
  const [waitingforllm, setWaitingForLlm] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [cloudId, setCloudId] = useState(null);
  const [selectedCloudId, setSelectedCloudId] = useState(null);
  const [aiStudioExapanded, setAiStudioExapanded] = React.useState(true);
  const [oaiStudioExapanded, setOaiStudioExapanded] = React.useState(false);
  const [platformId, setPlatformId] = useState(null)
  const [selectedPlatformId, setSelectedPlatformId] = useState(null)
  const [llmId, setLlmId] = useState(null)
  const [selectedLlmId, setSelectedLlmId] = useState(null)
  const [llmName, setLlmName] = useState(null)
  const [llms, setLLMs] = useState([]);
  const [inputDisabled, setinputDisabled] = useState(false)
  const scrollRef = useRef(null);
  let jwtToken = localStorage.getItem("genai-app-idtoken");
  let decodedToken = jwtDecode(jwtToken, { complete: true });
  const username = decodedToken.email;
  const [llmloading, setllmloading] = useState(false)
  const[chatloading,setChatloading]=useState(false)
  const [messageisDeleted, setmessageisDeleted] = useState('')
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [sessionToDelete, setSessionToDelete] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [action, setAction] = useState(null);


  useEffect(() => {
    if (messageisDeleted != "" && messageisDeleted == sessionid) {
      newChatSession()
      setmessageisDeleted("")
      setSessionId("")
    }

  }, [messageisDeleted])


  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevChatMessages = usePrevious(chatMessages);

  useEffect(() => {
    mountFunction();
  }, []);

  useEffect(() => {
    if (scrollRef.current) {
      if (
        prevChatMessages &&
        chatMessages &&
        prevChatMessages.length != chatMessages.length
      )
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatMessages]);

  async function mountFunction() {
    newChatSession();
    refreshChatSessionComponent();
    getLLMs();
  }

  const getLLMName = (llmid, cloud) => {
    for (let provider in llms) {
      for (let llm of llms[provider]) {
        if (llm.llmid === llmid) {
          return llm.llmname;
        }
      }
    }
    // setCloudId(cloud);
    return null;
  };

  const handleCancelDelete = () => {
    setOpenDialog(false); 
  };
  const handleConfirmDelete = () => {
    delete_Individual_chat(selectedChat, action); // Call your delete function
    setOpenDialog(false);
    setAnchorEl(false) // Close the dialog
  };
  const handleDeleteClick = (index,action,item) => {
    setSelectedChat(index);
    setAction(action);
    setOpenDialog(true); 
     // Open the confirmation dialog
  };
  const delete_Session = (userid, sessionid) => {
    exchange(`/api/chathistory/${userid}/${sessionid}`, "DELETE")
      // Use DELETE method
      .then((response) => {
        if (response.statusCode == 200) {
          return response;
        }
        else {
          throw new Error(`Failed to delete session: ${response.statusText}`);
        }
      })
      .then((data) => {
        console.log("Successfully deleted session:", data);
        refreshChatSessionComponent()
        setmessageisDeleted(sessionid)
        // setmessageisDeleted(true)
        // Optionally, refresh the session list here
      })
      .catch((error) => {
        console.error("Error deleting session:", error);
      });
  };

  const delete_Individual_chat = (index, action, item) => {
    // Use the index as chatid
    const chatid = index;  // Use index as the unique chat id
    
    let message = {status:action};
    console.log(JSON.stringify(message));
    setLoading(true)

    // Make the API call with chatid (using index)
    exchange(
      "/api/chathistory/" + username + "/" + sessionid + "/" + chatid,  // Use index as chatid
      "POST",
      { messages: message }
    )
      .then((data) => {
        console.log(data);
        if (data.status === 200) {
          let messages = [...chatMessages];
          let msgToModify = { ...messages[index] };
          msgToModify.status = action;
          messages[index] = msgToModify;
          setChatMessages(messages);
          // If action is 'Deleted', check if all messages are deleted
          if (action === "Deleted") {
            onChatSessionListItemClick({ sessionid, sessionname });
            let deleted_message = messages.filter(message => message.status == "Deleted")
            let assistant_messages = messages.filter(message => message.role == "assistant")
            console.log("deleted message:", deleted_message);
            toast.success("Successfully deleted session")
            // console.log("all messages:", messages);
            // If all messages are deleted, delete the session
            if (deleted_message.length === assistant_messages.length) {
              delete_Session(username,sessionid);
            }
          }
          setLoading(false)
        } else {
          console.error("Failed to Delete the session !!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
};



  const updateChatSession = (message) => {
    console.log(JSON.stringify(message));
    exchange(
      "/api/chathistory/" + username + "/" + sessionid + "/chats",
      "POST",
      { messages: message }
    )
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const refreshChatSessionComponent = () => {
    setChatloading(true)
    exchange(
      "/api/chathistory/" +
      username +
      "?searchType=Non-RAG&fields=sessionname,sessionid,updatedon",
      "GET",
      null
    )
      .then((data) => {
        let sessions = data.sort((a, b) => b.updatedon - a.updatedon);
        setChatSessions(sessions);
        setChatloading(false)

        console.log(data);
      })
      .catch((error) => console.log(error));
  };
  const Date_time = () => {
    const now = new Date();
    const localeString = now.toLocaleString([], {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
    const [date, time] = localeString.split(', ');
    return { date, time };

  }

  const onChatInputSendButtonClick = (input) => {
    setinputDisabled(true);
    setWaitingForLlm(true);
    const dateTime = Date_time();
    const dateTimeString = dateTime.date + "  " + dateTime.time;
    let message = { role: "user", content: input ,time: dateTimeString};
    updateChatSession(message);
    // setMetadata([]);
    setChatMessages((messages) => [...messages, message]);

    let url = `/api/nonrag/invoke/?cloud=${cloudId}&platform=${platformId}&llm=${llmId}&prompt=${input}`;
    if (sessionname === "New Chat") {
      url =
        url +
        "&setSubject=True&sessionid=" +
        encodeURIComponent(sessionid) +
        "&userid=" +
        encodeURIComponent(username) +
        "&searchType=Non-RAG";
    }

    // fetchData(input);

    exchange(url, "GET", null)
      .then((data) => {
        const dateTime = Date_time();
        const dateTimeString = dateTime.date + "  " + dateTime.time;
        message = {
          role: "assistant",
          content: data.result,
          citations: data.metadata,
          cloud: cloudId,
          llm: llmId,
          time: dateTimeString

        };
        setChatMessages((messages) => [...messages, message]);
        updateChatSession(message);
        // setMetadata(data.metadata);
        setinputDisabled(false);
        setWaitingForLlm(false);
        if (sessionname === "New Chat") {
          setSessionName(data.subject);
          refreshChatSessionComponent();
        }
        console.log(data);
      })
      .catch((error) => {
        console.error(error.message);
        setinputDisabled(false);
      });
    input = "";
  };


  const getLLMs = () => {
    const llmvalue = JSON.parse(localStorage.getItem("llms"))
    setllmloading(true);
    console.log("llmvalue",llmvalue)
    if (!llmvalue) {
      exchange(`${API_BASE_URL}/llms/`, "GET")
        .then((response) => {
          if (response != null) {
            localStorage.setItem("llms", JSON.stringify(response))
            setLLMs(response);
          }
          setllmloading(false);
        })
        .catch((error) => {
          console.error(error.message);
          setinputDisabled(false);
          setllmloading(false);
        });
    }
    else {
      setLLMs(llmvalue);
      setllmloading(false);
    }
  }

  const fetchConfigData = async (configType) => {
    try {
      const response = await exchange(
        `${API_BASE_URL}/config/${configType}`,
        "GET"
      );
      const doubleEncodedData = response.body;
      const data = JSON.parse(JSON.parse(doubleEncodedData));
      const defaultLlm = data.default_llm || [];

      // Set default values based on fetched config
      if (defaultLlm.length > 0) {
        const { cloud_id, platform_id, llm_id, llm_name } = defaultLlm[0];
        localStorage.setItem("cloud-id", cloud_id);
        if (platform_id) {
          localStorage.setItem("platform-id", platform_id);
        } else {
          localStorage.removeItem("platform-id")
        }
        localStorage.setItem("llm-id", llm_id);
        localStorage.setItem("llm-name", llm_name); // Adjust if needed

        // Update state from localStorage
        setSelectedLlm()
      }
    } catch (error) {
      console.error("Error fetching config data:", error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("llm-id")) {
      // Update state from localStorage
      setSelectedLlm()
    } else {
      // Update state after fetching configuration
      fetchConfigData("tenant_config");
    }

  }, []);

  const setSelectedLlm = () => {
    const storedCloudId = localStorage.getItem("cloud-id");
    const storedPlatformId = localStorage.getItem("platform-id");
    const storedLlmId = localStorage.getItem("llm-id");
    const storedLlmName = localStorage.getItem("llm-name");

    setCloudId(storedCloudId);
    setSelectedCloudId(storedCloudId);
    setPlatformId(storedPlatformId);
    setSelectedPlatformId(storedPlatformId);
    setLlmId(storedLlmId);
    setSelectedLlmId(storedLlmId);
    setLlmName(storedLlmName);
  }

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
    setSelectedCloudId(cloudId);
    setSelectedPlatformId(platformId)
    if (platformId == "ai-studio") {
      setAiStudioExapanded(true)
      setOaiStudioExapanded(false)
    } else if (platformId == "oai-studio") {
      setAiStudioExapanded(false)
      setOaiStudioExapanded(true)
    }
    setSelectedLlmId(llmId);
  };

  const handleCloudChange = (event) => {
    setSelectedCloudId(event.target.value);
    setSelectedLlmId(null)
    setAiStudioExapanded(true)
    setOaiStudioExapanded(false)
  };

  const handlePlatformChange = (event) => {
    setAiStudioExapanded(!aiStudioExapanded)
    setOaiStudioExapanded(!oaiStudioExapanded)
    setSelectedLlmId(null)
  }

  useEffect(() => {
    if (selectedCloudId == "azure") {
      if (aiStudioExapanded)
        setSelectedPlatformId("ai-studio");
      else if (oaiStudioExapanded)
        setSelectedPlatformId("oai-studio");
    } else {
      setSelectedPlatformId(null);
    }
  }, [selectedCloudId, aiStudioExapanded, oaiStudioExapanded]);

  const handleModelChange = (event) => {
    setSelectedLlmId(event.target.value);
  };

  const getCostIcon = (costCategory) => {
    const iconStyle = {
      height: "12px",
      width: "12px",
    };

    const generateIcons = (count, color, title) => {
      const icons = [];
      for (let i = 0; i < count; i++) {
        icons.push(
          <AttachMoneyIcon
            key={i}
            style={{
              ...iconStyle,
              color: color,
              marginLeft: i === 0 ? 0 : "-6px",
              zIndex: count - i,
            }}
          />
        );
      }
      return (
        <Tooltip title={title} placement="top">
          <Box display="flex" alignItems="center">
            {icons}
          </Box>
        </Tooltip>
      );
    };

    switch (costCategory) {
      case "Low":
        return (
          <Tooltip title="Cost : Low" placement="top">
            <AttachMoneyIcon style={{ ...iconStyle, color: "green" }} />
          </Tooltip>
        );
      case "Medium":
        return generateIcons(2, "green", "Cost : Medium");
      case "High":
        return generateIcons(3, "green", "Cost : High");
      default:
        return null;
    }
  };

  const applyLlmSelection = () => {
    setCloudId(selectedCloudId);
    localStorage.setItem("cloud-id", selectedCloudId)
    setPlatformId(selectedPlatformId)
    if (selectedPlatformId) {
      localStorage.setItem("platform-id", selectedPlatformId);
    } else {
      localStorage.removeItem("platform-id")
    }
    setLlmId(selectedLlmId)
    localStorage.setItem("llm-id", selectedLlmId)

    const values = Object.values(llms);
    values.forEach(value => {
      const llmData = value.find(llm => llm.cloudid == selectedCloudId && llm.platformid == selectedPlatformId
        && llm.llmid == selectedLlmId);
      if (llmData) {
        setLlmName(llmData.llmname)
        localStorage.setItem("llm-name", llmData.llmname)
      }
    })

    setDrawerOpen(false);
  }

  const onChatSessionListItemClick = (item, index) => {
    setchatSessionSelectedIndex(index);
    // setMetadata([]);
    setChatMessages([]);
    setSessionId(item.sessionid + "");
    setSessionName(item.sessionname);
    setLoading(true);
    exchange("/api/chathistory/" + username + "/" + item.sessionid, "GET")
      .then((data) => {
        setChatMessages((messages) => [...messages, ...data.messages]);
        setLoading(false);
        let listBottom = document.getElementById("listBottom");
        listBottom.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const newChatSession = () => {
    setChatMessages([]);
    // setMetadata([]);
    setLoading(true);
    setchatSessionSelectedIndex(-1);
    setStreamingData("");
    exchange("/api/uuid", "GET")
      .then((data) => {
        setSessionId(data.sessionid);
        setSessionName("New Chat");
        setLoading(false);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const updateFeedback = (index, action) => {
    let message = { feedback: action };
    console.log(JSON.stringify(message));
    exchange(
      "/api/chathistory/" + username + "/" + sessionid + "/chats/" + index,
      "POST",
      { messages: message }
    )
      .then((data) => {
        console.log(data);
        if (data.status === 200) {
          let messages = [...chatMessages];
          let msgToModify = { ...messages[index] };
          msgToModify.feedback = action;
          messages[index] = msgToModify;
          setChatMessages(messages);
        } else {
          console.error("Failed to update feedback!!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const filterUniqueCitations = (citations) => {
    const cits = new Set();
    return citations.filter((citation) => {
      let uri;
      uri = citation.metadata.location
        ? citation.metadata.location.s3Location.uri
        : citation.metadata.url;
      if (cits.has(uri)) {
        return false;
      } else {
        cits.add(uri);
        return true;
      }
    });
  };

  const filterLLMsByCloudProvider = (cloudProvider) => {
    if (cloudProvider in llms) {
      return llms[cloudProvider];
    }
    return [];
  };

  const tableStyles = `
    table {
      width: 100%;
      border-collapse: collapse;
    }
    th, td {
      border: 1px solid black;
      padding: 8px;
      text-align: left;
    }
  `;
  var displayMessages = "";
  displayMessages = (
    <Grid container height="90vh" style={{ overflow: "clip" }}>
      <Grid xs={3}>
        <Button
          variant="outlined"
          style={{ width: "90%", margin: "15px" }}
          endIcon={<ChatIcon />}
          onClick={newChatSession}
        >
          New Chat
        </Button>
        <div style={{ height: "70%", maxHeight: "80%", overflow: "auto" }}>
            <ChatSessions
              selectedIndex={chatSessionSelectedIndex}
              data={chatsessions}
              onChatSessionClick={onChatSessionListItemClick}
              loading={chatloading}
              setLoading={setLoading}
              username={username}
              refreshcomponent={refreshChatSessionComponent}
              setmessageisDeleted={setmessageisDeleted}
            />
        
        </div>
      </Grid>
      <Grid xs={9}>
        <Paper
          style={{
            height: "100%",
            maxHeight: "100%",
            overflow: "auto",
            margin: "1px 0px 0px 0px",
            backgroundColor: "#d6d6d6",
          }}
          elevation={3}
        >
          <Box height="75vh">
            <List
              style={{
                height: "100%",
                maxHeight: "100%",
                overflow: "auto",
                paddingLeft: "80px",
                paddingRight: "80px",
              }}
            >
              {chatMessages.map((chat, index, messages) => (
                !chat.isDeleted && (

                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={
                      <React.Fragment>
                        {/* Avatar and user/genAI indication */}
                        <Stack direction="row" spacing={2}>
                          {chat.role === "user" ? (
                            <Avatar
                              sx={{
                                width: 24,
                                height: 24,
                                bgcolor: green[500],
                              }}
                            >
                              <Person2Icon />
                            </Avatar>
                          ) : (
                            <Avatar
                              sx={{ width: 24, height: 24, bgcolor: pink[500] }}
                            >
                              <SmartToyIcon />
                            </Avatar>
                          )}
                          {chat.role === "user" ? (
                            <span style={{ fontWeight: "bold" }}>You</span>
                          ) : (
                            <span style={{ fontWeight: "bold" }}>
                              GenAI{" "}
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {chat.cloud || chat.llm ? (
                                  `(Cloud: ${chat.cloud.toUpperCase()}, Model: `
                                ) : null}
                                {chat.cloud || chat.llm ? getLLMName(chat.llm, chat.cloud) || <CircularProgress size={9}/> :null}
                                {`)`}
                              </Typography>{" "}
                            </span>
                          )}
                        </Stack>

                        {/* Chat content */}
                        <Stack direction="row" spacing={2}>
                          <div style={{ margin: "10px", marginBottom: "0px" }}>
                            &nbsp;
                          </div>
                          <Paper
                            elevation={2}
                            sx={{
                              backgroundColor:
                                chat.role === "user" ? "lightblue" : "white",
                              padding: "5px",
                              paddingLeft: "15px",
                              paddingRight: "15px",
                              marginRight: "70px",
                            }}
                          >
                            <div>
                              <style>{tableStyles}</style>
                              {chat.llm && chat.llm.startsWith('mistral') ? (
                                <div style={{ overflowWrap: 'break-word', whitespace: 'pre-wrap', wordBreak: 'keep-all', maxWidth: '100%' }}>
                                  {chat.content}
                  
                                </div>
                              ) : (
                                <div >
                                  <Markdown remarkPlugins={[remarkGfm]}>
                                    {chat.content}
                    
                                  </Markdown>
                                </div>
                              )}
                            </div>
                            <pre style={{ textAlign: 'right', color: 'gray', fontSize: '0.7em', marginTop: '5px' }}>
                              {chat.time}
                            </pre>
                          </Paper>
                        </Stack>

                        {/* Feedback icons */}
                        {chat.role === "user" ? null : (
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={{ mt: 1, ml: 1 }}
                          >
                            <div
                              style={{ margin: "10px", marginBottom: "0px" }}
                            >
                              &nbsp;
                            </div>
                            <ThumbUpIcon
                              style={{
                                color:
                                  chat.feedback === "liked"
                                    ? "blue"
                                    : "grey",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                updateFeedback(
                                  index,
                                  chat.feedback === "liked" ? null : "liked"
                                )
                              }
                            />
                            &nbsp;&nbsp;
                            <ThumbDownIcon
                              style={{
                                color:
                                  chat.feedback === "disliked"
                                    ? "darkred"
                                    : "grey",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                updateFeedback(
                                  index,
                                  chat.feedback === "disliked"
                                    ? null
                                    : "disliked"
                                )
                              }
                            />
                            {chat.role === "assistant" && (
                                  <div className="DeleteIcon"
                                  style={{
                                    marginTop:'-8px'
                               // Ensure it appears above content if overlapping
                                  }} >
                                    <IconButton  onClick={() => handleDeleteClick(index, chat.status="Deleted", chat)}>
                                      <DeleteOutlineOutlinedIcon />
                                    </IconButton>
                                  </div>
                                )}
                          </Stack>
                        )}

                        {/* Citations */}
                        {chat.citations && chat.citations.length > 0 && (
                          <Stack direction="row" spacing={1}>
                            {chat.citations.length > 0 ? (
                              <div style={{ marginLeft: "35px" }}></div>
                            ) : (
                              ""
                            )}
                            {filterUniqueCitations(chat.citations).slice(0, 3).map(
                              (citation, citationIndex) => (
                                <div key={citationIndex}>
                                  <Chip
                                    key={citationIndex}
                                    color="success"
                                    variant="outlined"
                                    icon={<PictureAsPdfOutlinedIcon />}
                                    label={
                                      <div>
                                        {citation.metadata.score && (
                                          <div>
                                            {citation.metadata.location.s3Location.uri
                                              .split(/[// ]+/)
                                              .pop()}{" "}
                                            |{" "}
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                color: "green",
                                              }}
                                            >
                                              Score:&nbsp;
                                            </span>
                                            {Math.round(
                                              citation.metadata.score * 100
                                            )}
                                            %
                                          </div>
                                        )}
                                        {citation.metadata["@search.score"] && (
                                          <div>
                                            {citation.metadata.url
                                              .split(/[// ]+/)
                                              .pop()}{" "}
                                            |{" "}
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                color: "green",
                                              }}
                                            >
                                              Score:&nbsp;
                                            </span>
                                            {Math.round(
                                              citation.metadata[
                                              "@search.score"
                                              ] * 10
                                            )}
                                            %
                                          </div>
                                        )}
                                      </div>
                                    }
                                  />
                                </div>
                              )
                            )}
                          </Stack>
                        )}
                      </React.Fragment>
                    }
                  />
                </ListItem>
                )
              ))}
              {waitingforllm == true ? (
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Stack direction="row" spacing={2}>
                          <Avatar
                            sx={{ width: 24, height: 24, bgcolor: pink[500] }}
                          >
                            <SmartToyIcon />
                          </Avatar>
                          <span style={{ fontWeight: "bold" }}>GenAI</span>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                          <div style={{ margin: "10px", marginBottom: "0px" }}>
                            &nbsp;
                          </div>
                          <Paper
                            elevation={2}
                            sx={{
                              backgroundColor: "white",
                              padding: "15px",
                              paddingLeft: "15px",
                              paddingRight: "15px",
                              marginRight: "70px",
                            }}
                          >
                            <>
                              <CircularProgress color="secondary" />
                            </>
                          </Paper>
                        </Stack>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ) : (
                ""
              )}
              {streaming == true ? (
                <ListItem alignItems="flex-start">
                  <ListItemIcon>
                    <SmartToyIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Markdown>{streamingData}</Markdown>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ) : (
                ""
              )}
              <div id="listBottom" ref={scrollRef}></div>
            </List>

            <ChatInput
              onSendButtonClick={onChatInputSendButtonClick}
              toggleDrawer={toggleDrawer}
              disabled={loading}
              inputDisabled={inputDisabled}
              setinputDisabled={setinputDisabled}
              cloud={cloudId}
              llmName={llmName}
            />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
  return (
    <div id="ByMyMessages">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {displayMessages}
      <Drawer
        open={drawerOpen}
        onClose={toggleDrawer}
        value={selectedLlmId}
        anchor="right"
        display="flex"
        sx={{ ".MuiDrawer-paper": { borderRadius: "10px 0 0 10px" } }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          px={2}
          py={1}
          sx={{ width: 300, paddingRight: 1 }}
        >
          <Typography sx={{ fontSize: "1rem", fontWeight: "500" }}>
            Preferences
          </Typography>
          <IconButton color="primary" onClick={toggleDrawer} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        {llmloading ? (
          <Box p={2} sx={{ width: 300 }}>
            <Skeleton variant="text" width={120} height={20} />
            <Skeleton variant="rectangular" height={40} sx={{ my: 1 }} />
            <Skeleton variant="text" width={160} height={20} />
            <Skeleton variant="rectangular" height={400} />
          </Box>
        ) : (
          <>
            <Box p={2} sx={{ width: 300 }}>
              <Typography
                sx={{
                  fontSize: "0.6875rem",
                  fontWeight: "700",
                  textTransform: "uppercase",
                  marginBottom: "8px",
                }}
              >
                Cloud
              </Typography>
              <ToggleButtonGroup
                color="primary"
                value={selectedCloudId}
                onChange={handleCloudChange}
                exclusive
                fullWidth
                aria-label="Cloud"
              >
                <ToggleButton value="aws">AWS</ToggleButton>
                <ToggleButton value="azure">Azure</ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Divider />
            <Box p={2} sx={{ width: 200 }}>
              <Typography
                sx={{
                  fontSize: "0.6875rem",
                  fontWeight: "700",
                  textTransform: "uppercase",
                }}
              >
                Providers & Models
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              sx={{ flexGrow: 1, width: 300, overflowY: "auto" }}
            >
              <Box p={2} pt={0}>
                <FormControl component="fieldset">
                  <RadioGroup
                    name="llm"
                    // checked={selectedLlmId}
                    value={selectedLlmId}
                    onChange={handleModelChange}
                    aria-label="LLM"
                  >
                    {selectedCloudId == "aws" && (<div>
                      {Object.keys(llms)
                        .filter((provider) =>
                          llms[provider].some(
                            (llm) => llm.cloudid === selectedCloudId
                          )
                        )
                        .map((provider) => (
                          <React.Fragment key={provider}>
                            <Typography
                              sx={{
                                fontWeight: "700",
                                fontSize: "0.8125rem",
                                ':not(:first-child)': { marginTop: "16px" },
                                textTransform: "uppercase",
                              }}
                            >
                              {provider.toUpperCase()}
                            </Typography>
                            <Divider />
                            {llms[provider]
                              .filter((llm) => llm.cloudid === selectedCloudId)
                              .map((llm) => (
                                <FormControlLabel
                                  key={llm.llmid}
                                  value={llm.llmid}
                                  control={<Radio />}
                                  label={
                                    <Box display="flex" alignItems="center">
                                      <Typography
                                        sx={{
                                          fontWeight: "400",
                                          fontSize: "0.8125rem",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {llm.llmname}
                                      </Typography>
                                      <Box ml={1} display="flex" alignItems="center">
                                        {getCostIcon(llm.costcategory)}
                                      </Box>
                                    </Box>
                                  }
                                />
                              ))}
                          </React.Fragment>
                        ))}
                    </div>)}
                    {selectedCloudId == "azure" && (<div>
                      <Accordion
                        expanded={aiStudioExapanded}
                        onChange={handlePlatformChange}
                        disableGutters
                        sx={{
                          boxShadow: "none",
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                        >
                          <Typography
                            sx={{
                              fontWeight: "700",
                              fontSize: "0.8125rem",
                              color: aiStudioExapanded ? blue[700] : undefined,
                              textTransform: "uppercase"
                            }}
                          >
                            AI Studio
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            paddingTop: "0px",
                            paddingBottom: "19px"
                          }}
                        >
                          {Object.keys(llms)
                            .filter((provider) =>
                              llms[provider].some(
                                (llm) => llm.cloudid === selectedCloudId && llm.platformid == "ai-studio"
                              )
                            )
                            .map((provider) => (
                              <React.Fragment key={provider}>
                                <Typography
                                  sx={{
                                    fontWeight: "700",
                                    fontSize: "0.8125rem",
                                    marginTop: "16px",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {provider.toUpperCase()}
                                </Typography>
                                <Divider />
                                {llms[provider]
                                  .filter((llm) => llm.cloudid === selectedCloudId && llm.platformid == "ai-studio")
                                  .map((llm) => (
                                    <FormControlLabel
                                      key={llm.llmid}
                                      value={llm.llmid}
                                      control={<Radio />}
                                      label={
                                        <Box display="flex" alignItems="center">
                                          <Typography
                                            sx={{
                                              fontWeight: "400",
                                              fontSize: "0.8125rem",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            {llm.llmname}
                                          </Typography>
                                          <Box ml={1} display="flex" alignItems="center">
                                            {getCostIcon(llm.costcategory)}
                                          </Box>
                                        </Box>
                                      }
                                    />
                                  ))}
                              </React.Fragment>
                            ))}
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={oaiStudioExapanded}
                        onChange={handlePlatformChange}
                        disableGutters
                        sx={{
                          boxShadow: "none",
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                          marginTop: 1,
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                        >
                          <Typography
                            sx={{
                              fontWeight: "700",
                              fontSize: "0.8125rem",
                              color: oaiStudioExapanded ? blue[700] : undefined,
                              textTransform: "uppercase"
                            }}
                          >
                            OpenAI Studio
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            paddingTop: "0px",
                            paddingBottom: "19px"
                          }}
                        >
                          {Object.keys(llms)
                            .filter((provider) =>
                              llms[provider].some(
                                (llm) => llm.cloudid === selectedCloudId && llm.platformid == "oai-studio"
                              )
                            )
                            .map((provider) => (
                              <React.Fragment key={provider}>
                                <Typography
                                  sx={{
                                    fontWeight: "700",
                                    fontSize: "0.8125rem",
                                    marginTop: "16px",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {provider.toUpperCase()}
                                </Typography>
                                <Divider />
                                {llms[provider]
                                  .filter((llm) => llm.cloudid === selectedCloudId && llm.platformid == "oai-studio")
                                  .map((llm) => (
                                    <FormControlLabel
                                      key={llm.llmid}
                                      value={llm.llmid}
                                      control={<Radio />}
                                      label={
                                        <Box display="flex" alignItems="center">
                                          <Typography
                                            sx={{
                                              fontWeight: "400",
                                              fontSize: "0.8125rem",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            {llm.llmname}
                                          </Typography>
                                          <Box ml={1} display="flex" alignItems="center">
                                            {getCostIcon(llm.costcategory)}
                                          </Box>
                                        </Box>
                                      }
                                    />
                                  ))}
                              </React.Fragment>
                            ))}
                        </AccordionDetails>
                      </Accordion>
                    </div>)}
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>

            <Divider />
            <Box
              display="flex"
              justifyContent="center"
              px={2}
              py={1}
              sx={{ width: 300 }}
            >
              <Button
                color="primary"
                startIcon={<CheckIcon />}
                onClick={applyLlmSelection}
                disabled={!selectedLlmId}
              >
                Apply
              </Button>
            </Box>
          </>
        )}

      </Drawer>
      <Dialog open={openDialog} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this chat session? This action cannot be undone.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}
