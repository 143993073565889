import * as React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { toast } from "react-toastify";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import BusinessIcon from "@mui/icons-material/Business";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserGear } from "@fortawesome/free-solid-svg-icons";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { exchange } from "../common/ApiUtils";
import { useState, useEffect, useRef } from "react";
import TablePagination from "@mui/material/TablePagination";
import { API_BASE_URL } from "../App";
import { Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import CircularProgress from "@mui/material/CircularProgress";
import BusinessUnit from "./BusinessUnitpage";

// import jwtDecode from 'jwt-decode';

const drawerWidth = 240;

function User_main() {
  const [selectedView, setSelectedView] = useState("Users");
  const [errors, setErrors] = useState({});
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [users, setUsers] = useState([]);
  const [selecteduser, setselectedUser] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = React.useState(false);
  const [spinningUser, setSpinningUser] = useState(false);

  // const [loading,setloading]=useState(false)
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
    businessUnit: "select-business-unit",
    role: "SelectRole",
  });
  const [businessUnits, setBusinessUnits] = useState([]);
  const [loading, setLoading] = useState(false);
  const hasFetched = useRef(false);
  // const hasFetcheduser = useRef(false);

  const handleListItemClick = (text) => {
    if (text === "Business Units") {
      setSelectedView("Business Units");
    } else if (text === "Users") {
      setSelectedView("Users");
    }

    // else if(text ==='Roles'){
    //   setSelectedView("Roles")
    // }
    else {
      console.log(text);
    }
  };

  useEffect(() => {
    if (!hasFetched.current) {
      getUser(true);
      fetchConfigData("tenant_config");
      hasFetched.current = true;
    }
  }, []);

  const fetchConfigData = async (configType) => {
    try {
      const response = await exchange(
        `${API_BASE_URL}/config/${configType}`,
        "GET"
      );
      const doubleEncodedData = response.body;
      const data = JSON.parse(JSON.parse(doubleEncodedData));
      // Map supported_clouds with departments
      const mappedClouds = data.supported_clouds.map((cloud) => {
        const cloudDepartments =
          cloud.departments && cloud.departments !== ""
            ? cloud.departments.split(",")
            : [];
        // Filter departments based on cloud's departments list
        const mappedDepartments = data.departments.filter((department) =>
          cloudDepartments.includes(department.key)
        );
        const departmentNames = mappedDepartments.map(
          (department) => department.name
        );
        console.log(departmentNames, "departmentNames");
        // setBusinessUnits(Array.from(new Set(allDepartmentNames)));
        return {
          ...cloud,
          mappedDepartments: mappedDepartments,
        };
      });

      const allDepartmentNames = mappedClouds.flatMap((cloud) =>
        cloud.mappedDepartments.map((department) => department)
      );

      setBusinessUnits(Array.from(new Set(allDepartmentNames)));
    } catch (error) {
      console.error("Error fetching config data:", error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear error for the field as user types
    if (name === "email" && /\S+@\S+\.\S+/.test(value)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
    if (name === "password" && value) {
      const passwordValidationRegex =
        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>\/?]).{8,}$/;
      if (passwordValidationRegex.test(value)) {
        setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
      }
    }
    if (name === "role" && value !== "SelectRole") {
      setErrors((prevErrors) => ({ ...prevErrors, role: "" }));
    }
  };
  //setting the businessunit to empty when role == tenant admin
  useEffect(() => {
    if (formData.role === "tenant_admin") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        businessUnit: "",
      }));
    }
  }, [formData.role]);

  const getUser = (isLoader, action) => {
    const Token = localStorage.getItem("genai-app-idtoken");
    const loggedInAdminTenant = getTokenTenant(Token);
    if (isLoader) setLoading(true);
    exchange("/api/admin/users", "GET", null).then((response) => {
      setLoading(false);
      const data = response.users;
      console.log("Nonfiltereddata", data);
      if (Array.isArray(data)) {
        const filteredUsers = data.filter(
          (user) => user["custom:tenant"] === loggedInAdminTenant
        );
        // console.log(filteredUsers,"filteredUsers")
        setUsers(filteredUsers);
        if (action == "add") {
          toast.success(" User added Sucesfully");
          setSpinningUser(false);
          handleClose();
        }
      } else {
        console.error("Data is not an array");
      }
    });
  };

  const handleDeleteClick = (user) => {
    setselectedUser(user);
    console.log("User details: ", user);
    setShowDeleteConfirmDialog(true);
  };

  const handleDelete = () => {
    setSpinningUser(true);
    exchange(`${API_BASE_URL}/admin/users/${selecteduser?.email}`, "DELETE")
      .then((response) => {
        console.log(response);
        setSpinningUser(false);
        if (response.statusCode == 200) {
          getUser();
          toast.success("User deleted successfully");
          setShowDeleteConfirmDialog(false);
          setUsers((prevUsers) =>
            prevUsers.filter((user) => user.email !== selecteduser?.email)
          );
        } else {
          toast.error("User does not exist.");
        }
        // toast.success
      })
      .catch((error) => {
        toast.error("An error occurred.", error);
        setSpinningUser(false);
      });
  };

  //validation

  // adding the config
  const handleSubmit = () => {
    const payload = {
      email: formData.email,
      businessUnit: formData.businessUnit,
      password: formData.password, // Corrected typo
      role: formData.role,
    };
    setSpinningUser(true);
    exchange(`${API_BASE_URL}/admin/users`, "POST", payload)
      // setSpinningUser(true)
      .then((response) => {
        console.log("response", response);
        if (response.status == "SUCCESS") {
          getUser(false, "add");
        } else {
          if (response.error.includes("Email already exists")) {
            toast.error("User with this email already exists");
            setSpinningUser(false);
          } else {
            toast.error("An error occurred while adding the user");
            setSpinningUser(false);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("An error occurred while adding the user");
        setSpinningUser(false);
      });
  };

  function getTokenTenant(token) {
    // Parse JWT token to extract the tenant
    if (!token) return null;

    const tokenParts = token.split(".");
    if (tokenParts.length !== 3) return null;

    const payload = JSON.parse(atob(tokenParts[1]));
    return payload["custom:tenant"];
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //Role
  const formatRole = (role) => {
    switch (role) {
      case "user":
        return "Department User";
      case "tenant_admin":
        return "Tenant Admin";
      case "department_admin":
        return "Department Admin";
      default:
        return role;
    }
  };
  //   const handleListItemClick = (text) => {
  //     setSelectedItem(text);
  // };

  var displayMessages = "";
  displayMessages = (
    <div className="Main_box">
      <Grid container height="90vh" style={{ overflow: "clip" }}>
        <Grid xs={2}>
          <Grid style={{ marginTop: "20px" }}>
            {["Roles", "Users", "Business Units"].map((text) => (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  onClick={() => handleListItemClick(text)}
                  style={{
                    backgroundColor:
                      selectedView === text ? "#f0f0f0" : "transparent",
                    color: selectedView === text ? "#000" : "#555",
                  }}
                >
                  <ListItemIcon>
                    {text === "Roles" && (
                      <FontAwesomeIcon
                        icon={faUserGear}
                        style={{ marginLeft: "4px" }}
                      />
                    )}
                    {text === "Users" && <SupervisorAccountIcon />}
                    {text === "Business Units" && <BusinessIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </Grid>
        </Grid>
        <Grid xs={10}>
          {selectedView === "Business Units" && <BusinessUnit />}
          {/* {selectedView === 'Users' && <User_main />} */}
          <Dialog
            open={showDeleteConfirmDialog}
            onClose={() => handleDelete()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm Delete"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Do you want to delete '{selecteduser?.email}'?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleDelete()}
                color="primary"
                disabled={spinningUser}
              >
                {spinningUser ? <CircularProgress size={24} /> : "YES"}
              </Button>
              <Button
                onClick={() => setShowDeleteConfirmDialog(false)}
                color="primary"
                autoFocus
                disabled={spinningUser}
              >
                NO
              </Button>
            </DialogActions>
          </Dialog>

          <Paper
            style={{
              height: "100%",
              maxHeight: "100%",
              overflow: "auto",
              margin: "1px 0px 0px 0px",
              backgroundColor: "#d6d6d6",
            }}
            elevation={3}
          >
            <Box height="75vh">
              {loading && (
                <>
                  <Skeleton
                    variant="text"
                    height={100}
                    style={{
                      marginTop: "20px",
                      height: "75px",
                      marginLeft: "11%",
                      marginRight: "13%",
                    }}
                  />
                </>
              )}
              {!loading && (
                <div
                  style={{
                    backgroundColor: "white",
                    marginRight: "24px",
                    marginLeft: "24px",
                    borderRadius: "8px",
                    padding: "15px",
                  }}
                >
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClickOpen}
                      style={{ marginLeft: "88%", marginTop: "25px" }}
                    >
                      Create User
                    </Button>

                    <Typography variant="h6" style={{ marginTop: "-30px" }}>
                      {" "}
                      Users ({users.length})
                    </Typography>
                  </>
                </div>
              )}
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Create User</DialogTitle>
                <DialogContent>
                  <TextField
                    margin="dense"
                    name="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <span
                      className="error"
                      style={{
                        color: "red",
                        fontSize: "x-small",
                        marginLeft: "6px",
                        fontWeight: "bold",
                      }}
                    >
                      {errors.email}
                    </span>
                  )}
                  <TextField
                    margin="dense"
                    name="password"
                    label="Password"
                    type="password"
                    fullWidth
                    value={formData.password}
                    onChange={handleChange}
                  />
                  {/* {!errors.password && <span className="error"></span>} */}
                  {errors.password && (
                    <span
                      className="error"
                      style={{
                        color: "red",
                        fontSize: "x-small",
                        marginLeft: "6px",
                        fontWeight: "bold",
                      }}
                    >
                      {errors.password}
                    </span>
                  )}
                  <Select
                    style={{ marginTop: "8px" }}
                    margin="dense"
                    name="role"
                    label="role"
                    fullWidth
                    value={formData.role}
                    onChange={handleChange}
                  >
                    <MenuItem value="SelectRole">Select a Role</MenuItem>
                    <MenuItem value="tenant_admin">Tenant Admin</MenuItem>
                    <MenuItem value="user">Department Users</MenuItem>
                    <MenuItem value="department_admin">
                      Department Admin
                    </MenuItem>
                  </Select>
                  {errors.role && (
                    <span
                      className="error"
                      style={{
                        color: "red",
                        fontSize: "x-small",
                        marginLeft: "6px",
                        fontWeight: "bold",
                      }}
                    >
                      {errors.role}
                    </span>
                  )}
                  <Select
                    style={{ marginTop: "8px" }}
                    margin="dense"
                    name="businessUnit"
                    label="BusinessUnit"
                    fullWidth
                    value={formData.businessUnit}
                    onChange={handleChange}
                    disabled={formData.role === "tenant_admin"}
                  >
                    <MenuItem value="select-business-unit">
                      Select BusinessUnit
                    </MenuItem>
                    {businessUnits.map((unit, index) => (
                      <MenuItem key={index} value={unit.key}>
                        {unit.name}
                      </MenuItem>
                    ))}
                  </Select>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    color="primary"
                    disabled={spinningUser}
                  >
                    {spinningUser ? <CircularProgress size={24} /> : "Submit"}
                  </Button>
                </DialogActions>
              </Dialog>
              <Box
                sx={{ display: "flex", overflow: "hidden", marginTop: "-80px" }}
              >
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                    // bgcolor: 'background.default',
                    p: 3,
                    mt: 8,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                  }}
                >
                  <div>
                    <>
                      <Box sx={{ width: "100%" }}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          {loading && (
                            <Box sx={{ width: "100%", marginLeft: "16%" }}>
                              {/* Skeleton for TableRow */}
                              {Array.from(new Array(rowsPerPage)).map(
                                (_, index) => (
                                  <TableRow
                                    key={index}
                                    sx={{
                                      backgroundColor:
                                        index % 2 === 0 ? "#d6d6d6" : "#d6d6d6",
                                    }}
                                  >
                                    {/* Skeleton for TableCell */}
                                    <TableCell>
                                      <Skeleton variant="text" width={40} />
                                    </TableCell>
                                    <TableCell>
                                      <Skeleton variant="text" width={200} />
                                    </TableCell>
                                    <TableCell>
                                      <Skeleton variant="text" width={200} />
                                    </TableCell>
                                    <TableCell>
                                      <Skeleton variant="text" width={150} />
                                    </TableCell>
                                    <TableCell>
                                      <Skeleton
                                        variant="circular"
                                        width={24}
                                        height={24}
                                        animation="pulse"
                                      />
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </Box>
                          )}
                          {!loading && (
                            <TableContainer
                              component={Paper}
                              sx={{ marginTop: "-7px" }}
                            >
                              <Table
                                aria-label="striped table"
                                style={{ marginTop: "2%" }}
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell style={{ width: "15%" }}>
                                      ID
                                    </TableCell>
                                    <TableCell style={{ width: "25%" }}>
                                      Email Address
                                    </TableCell>
                                    {/* Adjusted width */}
                                    <TableCell style={{ width: "25%" }}>
                                      Business Unit
                                    </TableCell>
                                    <TableCell style={{ width: "25%" }}>
                                      Role
                                    </TableCell>
                                    <TableCell style={{ width: "15%" }}>
                                      Actions
                                    </TableCell>{" "}
                                    {/* Adjusted width */}
                                    {/* Adjusted width */}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {users
                                    .slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                    .map((user, index) => (
                                      <TableRow
                                        key={user.id}
                                        sx={{
                                          backgroundColor:
                                            index % 2 === 0
                                              ? "white"
                                              : "#f5f5f5",
                                        }}
                                      >
                                        <TableCell>
                                          {index + 1 + page * rowsPerPage}
                                        </TableCell>
                                        <TableCell>{user.email}</TableCell>{" "}
                                        {/* Adjusted width */}
                                        <TableCell>
                                          {user["custom:department"]}
                                        </TableCell>
                                        <TableCell>
                                          {formatRole(user.role)}
                                        </TableCell>
                                        <TableCell>
                                          <button
                                            onClick={() =>
                                              handleDeleteClick(user)
                                            }
                                            style={{
                                              background: "none",
                                              border: "none",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <DeleteIcon />
                                          </button>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                              <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={users.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                            </TableContainer>
                          )}
                        </Box>
                      </Box>
                    </>
                  </div>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );

  // const drawer = (
  //   <div>
  //     {/* <button onClick={token()}> clickme</button> */}

  //     <Grid>
  //       {['Roles', 'Users', 'Business Units'].map((text) => (
  //         <ListItem key={text} disablePadding>
  //           <ListItemButton>
  //             <ListItemIcon>
  //               {text === 'Roles' && <FontAwesomeIcon icon={faUserGear} style={{ marginLeft: '4px' }} />}
  //               {text === 'Users' && <SupervisorAccountIcon />}
  //               {text === 'Business Units' && <BusinessIcon />}
  //             </ListItemIcon>
  //             <ListItemText primary={text} />
  //           </ListItemButton>
  //         </ListItem>
  //       ))}
  //     </Grid>
  //   </div>
  // );

  // const container = window !== undefined ? () => window().document.body : undefined;
  return (
    // <div className='main' style={{ border: '1px' }}>
    <>
      {displayMessages}

    </>
    // {/* </div > */}
  );
}

// ResponsiveDrawer.propTypes = {
//   window: PropTypes.func,
// };

export default User_main;
