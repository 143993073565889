import React, { useState, useRef } from 'react';
import {
  Container,
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Paper,
  CircularProgress
} from '@mui/material';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faFile } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL } from '../App';
import { exchange } from '../common/ApiUtils';


const CodeEditor = ({ inputData, handleInputChange, fileName }) => {
  return (
    <div>
    <Typography variant="h6">Code Input:</Typography>
    <TextField
      multiline
      rows={15}
      variant="outlined"
      placeholder={fileName ? fileName : "Enter your code here..."}
      value={fileName ? '' : inputData}
      onChange={handleInputChange}
      fullWidth
      InputProps={{
        readOnly: !!fileName,
      }}
    />
    <Typography variant="h6" style={{ marginTop: '16px' }}>Additional Instructions (Optional):</Typography>
    <TextField
      id="description" // field id
      multiline
      rows={3}
      variant="outlined"
      placeholder="Enter instructions for code conversion..."
      fullWidth
    />
  </div>
  );
};

const MessageDisplay = ({ output }) => {
  return (
    <div>
      <Typography variant="h6">Output:</Typography>
      <TextField
        multiline
        rows={22}
        variant="outlined"
        value={output}
        InputProps={{
          readOnly: true,
        }}
        fullWidth
      />
    </div>
  );
};

const Codeconvertor = () => {
  const [inputData, setInputData] = useState('');
  const [fileName, setFileName] = useState('');
  const [isFile, setIsFile] = useState(false);
  const [convertTo, setConvertTo] = useState('Java');
  const [result, setResult] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fileInputRef = useRef(null);

  const handleInputChange = (event) => {
    setInputData(event.target.value);
    setIsFile(false);
    setFileName('');
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setInputData(file);
      setFileName(file.name);
      setIsFile(true);
    }
  };

  const handleConvertToChange = (event) => {
    setConvertTo(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setResult('');
    const description = document.getElementById('description').value;
    // const formData = new FormData();
    // if (isFile) {
    //   formData.append('file', inputData);
    // } else {
    //   formData.append('input_data', inputData);
    // }
    // formData.append('convert_to', convertTo);
    let reqstBdy = {
      "convert_to": convertTo,
      "input_data": description,
      "input_code": inputData
    };
    try {
      exchange(`${API_BASE_URL}/codeconverter`, "POST", reqstBdy)
        .then(data => {
          if(!data){
            console.log("Error Message", data)
            toast.error("Please check all the fields ");
            setIsLoading(false);
          }
          else if (data?.status === "success") {
            setResult(data.Answer);
            toast.success('Conversion successful!');
            setIsLoading(false);
          } else {
            console.log("Error Message", data)
            toast.error(data.message);
            setIsLoading(false);
 
          }
        });
    } catch (error) {
      console.error('Error:', error);
      toast.error('Conversion failed.');
      setIsLoading(false);
    }
  };

  return (
    <Container>
    <Typography variant="h3" component="h1" gutterBottom>
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <CodeEditor inputData={inputData} handleInputChange={handleInputChange} fileName={fileName} />
        <div style={{ marginTop: '16px', display: 'flex', alignItems: 'center' }}>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </div>
      </Grid>
      <Grid item xs={2}>
        <FormControl size='small' variant="outlined" style={{ minWidth: 140, marginRight: '16px', marginTop:'190px', width:'116px' }}>
          <InputLabel>Convert to</InputLabel>
          <Select value={convertTo || "Java"} onChange={handleConvertToChange} label="Select Language" autoWidth>
            <MenuItem value="">
            </MenuItem>
            <MenuItem value="Python">Python</MenuItem>
            <MenuItem value="JavaScript">JavaScript</MenuItem>
            <MenuItem value="Java">Java</MenuItem>
          </Select>
        </FormControl>
        <div style={{ marginTop: '10px', marginLeft: '25px', padding: '2px' }}>
          <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Convert'}
          </Button>
        </div>
      </Grid>
      <Grid item xs={5} md>
        <MessageDisplay output={result} />
      </Grid>
    </Grid>
  </Container>
  );
};

export default Codeconvertor;