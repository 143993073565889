import React, { useState, useEffect } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import moment from "moment";
import { Skeleton, Typography, Divider, IconButton, Menu, MenuItem, Tooltip, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Input, Paper, InputBase } from "@mui/material";
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import EditIcon from '@mui/icons-material/Edit';
import EditIcon from '@mui/icons-material/CreateOutlined';
import { exchange } from "../common/ApiUtils";
import { Done } from "@mui/icons-material";
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';



export default function ChatSessions(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [sessionToDelete, setSessionToDelete] = useState(null);
  const [isEdit, setIsEdit] = useState(false)
  const [selectedItem, setSelectedItem] = useState()
  const { username, setUsername, setLoading } = props
  const { refreshcomponent, refreshChatComponent } = props
  const [newSessionName, setNewSessionName] = useState('')
  const { messageisDeleted, setmessageisDeleted } = props
  const [toggleState, setToggleState] = React.useState({ toggle: true });




  const handleClick = (event, session) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget); // Set anchor for the menu
  };
  const handleDeleteClick = (event, userid, sessionid) => {
    event.stopPropagation(); // Prevent parent onClick
    setSessionToDelete(sessionid); // Set the session ID to be deleted
    setOpenDialog(true);
    setAnchorEl(false)
    // Open the confirmation dialog
  };
  const handleConfirmDelete = () => {
    delete_Session(null, username, sessionToDelete); // Call your delete function
    setOpenDialog(false);
    setAnchorEl(false) // Close the dialog
  };
  const handleCancelDelete = () => {
    setOpenDialog(false); // Close the dialog without deleting
    setSessionToDelete(null);
    setAnchorEl(false) // Clear the session to delete
  };
  const handleClose = () => {
    setAnchorEl(null); // Close the menu
  };

  //Fetch chatsession 


  const delete_Session = (event, userid, sessionid) => {
    setLoading(true)
    exchange(`/api/chathistory/${userid}/${sessionid}`, "DELETE")
      // Use DELETE method
      .then((response) => {
        if (response.statusCode == 200) {
          return response;
        }
        else {
          throw new Error(`Failed to delete session: ${response.statusText}`);
        }
      })
      .then((data) => {
        // console.log("Successfully deleted session:", data.isdeleted);
        setLoading(false)
        toast.success('Successfully deleted session ')
        refreshcomponent()
        setmessageisDeleted(sessionid)
        // setmessageisDeleted(true)
        // Optionally, refresh the session list here
      })
      .catch((error) => {
        console.error("Error deleting session:", error);
      });
  };

  const Update_session = (userid, sessionid, sessionname) => {
    const payload = {
      sessionName: sessionname
    }
    setLoading(true)
    exchange(`/api/chathistory/${userid}/${sessionid}`, "PUT", payload)
      .then((response) => {
        return response;
      })
      .then((data) => {
        setLoading(false)
        setIsEdit(false)
        refreshcomponent()
        console.log("Successfully updated session:", data);
        // Optionally, refresh the session list or UI here
      })

      .catch((error) => {
        console.error("Error updating session:", error);
      });
  };

  return (
    <>
      <List>
        {props.loading ? (
          <>
            <ListItem style={{ padding: "0px 15px 0px 25px", marginBottom: '3px' }}>
              <Skeleton variant="text" sx={{ fontSize: '1.5rem', width: '100%' }} />
            </ListItem>
            <ListItem style={{ padding: "0px 15px 0px 25px", marginBottom: '3px' }}>
              <Skeleton variant="text" sx={{ fontSize: '1.5rem', width: '100%' }} />
            </ListItem>
            <ListItem style={{ padding: "0px 15px 0px 25px", marginBottom: '3px' }}>
              <Skeleton variant="text" sx={{ fontSize: '1.5rem', width: '100%' }} />
            </ListItem>
            <ListItem style={{ padding: "0px 15px 0px 25px", marginBottom: '3px' }}>
              <Skeleton variant="text" sx={{ fontSize: '1.5rem', width: '100%' }} />
            </ListItem>
            <ListItem style={{ padding: "0px 15px 0px 25px", marginBottom: '3px' }}>
              <Skeleton variant="text" sx={{ fontSize: '1.5rem', width: '100%' }} />
            </ListItem>
          </>
        ) : (
          props.data && props.data.length > 0 ? (
            props.data.map((session, index) => (
              <React.Fragment key={index}>
                <ListItem
                  selected={index === props.selectedIndex}
                  style={{ padding: "0px 0px 0px 25px" }}
                  onMouseEnter={() => {
                    setIsHovered(index)
                  }}
                  onMouseLeave={() => setIsHovered(null)}
                >
                  {(isEdit && session.sessionid == selectedItem) ? <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      defaultValue={session.sessionname}
                      onChange={(e) => setNewSessionName(e.target.value)}
                      inputProps={{ 'aria-label': 'session-name' }}
                    />
                    <IconButton onClick={(e) => {
                      e.stopPropagation()
                      setIsEdit(false)
                    }} 
                    sx={{
                      '&:hover': {
                        background: 'none'  // Removes any background on hover
                      },
                      '&.MuiIconButton-root': {  // Ensures no background in any state
                        background: 'none'
                      },
                      p: '10px 0px 10px 10px'
                    }}
                    type="button" aria-label="search">
                      <Done
                        onClick={(event) => {
                          event.stopPropagation();  // Prevent the event from bubbling up

                          // Check if the session name has changed
                          if (newSessionName && newSessionName.trim() !== session.sessionname?.trim()) {
                            // Update only if there's a change
                            Update_session(username, selectedItem, newSessionName);
                        }

                          // Exit edit mode regardless of whether the name changed or not
                          setIsEdit(false);
                          setAnchorEl(null);  // Close the menu
                        }}
                        style={{ marginRight: '8px' }}
                      />
                    </IconButton>
                    <IconButton onClick={(e) => {
                      e.stopPropagation();
                      setIsEdit(false);  // Close the edit mode
                    }} 
                    sx={{
                      '&:hover': {
                        background: 'none'  // Removes any background on hover
                      },
                      '&.MuiIconButton-root': {  // Ensures no background in any state
                        background: 'none'
                      },
                      p: '10px 0px 10px 10px'
                    }}
                    type="button" aria-label="close">
                      <CloseIcon style={{ marginRight: '8px' }} />
                    </IconButton>
                  </Paper> : <ListItemButton style={{ padding: "2px" }} onClick={() => props.onChatSessionClick(session, index)}><ListItemText
                    primary={session.sessionname}
                    secondary={moment(new Date(parseFloat(session.updatedon))).format("DD-MMM-YYYY")}
                    primaryTypographyProps={{ style: { width: '77%', display: 'inline-flex', marginTop: '3px', marginRight: '10px' } }}
                    secondaryTypographyProps={{ style: { display: '-webkit-inline-box', fontSize: 'smaller' } }}
                  /></ListItemButton>}
                  <div
                  // style={{width: !(isHovered == index) && '0px'}}
                  >
                    <IconButton
                      onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation();
                        setSelectedItem(session.sessionid) // Prevent parent onClick
                        handleClick(event);
                      }}
                      size="small"
                      color="default"
                      style={{
                        visibility: isHovered === index ? "visible" : "hidden",
                        opacity: isHovered === index ? 1 : 0,
                        transition: "visibility 0.3s ease, opacity 0.3s ease",
                        // backgroundColor: "#fafafa"
                      }}
                      sx={{
                        '&:hover': {
                          background: 'none'  // Removes any background on hover
                        },
                        '&.MuiIconButton-root': {  // Ensures no background in any state
                          background: 'none'
                        }
                      }}
                    >
                      <Grid item>
                        <Tooltip title="Options" placement="right-start">
                          <MoreVertIcon style={{ width: '18px', marginBottom: '-8px' }} />
                        </Tooltip>
                      </Grid>
                    </IconButton>
                  </div>
                </ListItem>
                <Divider component="li" />
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  slotProps={{
                    paper: {
                      style: {
                        boxShadow: 'none'
                      },
                    },
                  }}
                // PaperProps={{
                //   style: {
                //     borderRadius: 'none', // Optional: To match rounded edges style
                //     boxShadow: '0px 4px 12px rgba(0,0,0,0.15)', // Optional: Subtle shadow like ChatGPT
                //   }
                // }}
                >
                  <MenuItem onClick={(event) => {
                    event.stopPropagation()
                    setIsEdit(true)
                    setAnchorEl(false)
                    // Update_session(event,username,props.data[0].sessionid)
                  }}>
                    <EditIcon sx={{ marginRight: .5 }} />
                    Edit
                  </MenuItem>
                  <MenuItem onClick={(event, userid, sessionid) => {
                    event.stopPropagation(); // Prevent parent onClick
                    handleDeleteClick(event, username, selectedItem);
                  }} style={{ marginRight: '8px' }} >
                    <DeleteOutlineTwoToneIcon sx={{ marginRight: "4px" }} />
                    Delete
                  </MenuItem>
                </Menu>
              </React.Fragment>
            ))

          ) : (
            <Typography variant="body1" color="textSecondary" style={{ padding: '20px', textAlign: 'center' }}>
              No chats to display
            </Typography>
          )
        )}
      </List>

      <Dialog open={openDialog} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this chat session? This action cannot be undone.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );

}