import UserMainPage from "./User/UserMainPage";
import SignIn from "./Components/Login";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AdminMainPage from "./Admin/AdminMainPage";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline, createTheme } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
export const API_BASE_URL = "/api";
export const COGNITO_BASE_URL =
  "https://bedrock-kb.auth.us-east-1.amazoncognito.com/";
export const APP_REDIRECT_URI = "https://d1oooj8cfh4ofb.cloudfront.net/"; //https://d1oooj8cfh4ofb.cloudfront.net/
export const APP_CLIENT_ID = "pckqaa91fvis4jaajqpfiou11";

function App() {
  const theme = createTheme({
    palette: {
      mode: "light",
    },
    typography: {
      fontSize: 12,
      body1: {
        fontWeight: 400,
      },
    },
  });

  return (
    
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer
       position="bottom-right" />
      <Router>
        <Switch>
          <Route exact path="/">
            <SignIn />
          </Route>
          <Route exact path="/home">
            <UserMainPage userRole="user" />
          </Route>
          <Route exact path="/admin">
            <AdminMainPage userRole="admin" />
          </Route>
          <Route exact path="/d/admin">
            <AdminMainPage userRole="department_admin" />
          </Route>
          <Route exact path="/t/admin">
            <AdminMainPage userRole="superadmin" />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
