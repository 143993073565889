import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import SettingsIcon from '@mui/icons-material/Settings';
import { LinearProgress, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import awsicon from "../images/aws-icon.png"
import azureicon from "../images/azure-icon.png"
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { API_BASE_URL } from "../App";
import { exchange } from '../common/ApiUtils';
import { CircularProgress } from '@mui/material';




export default function ChatInput(props) {
    const { selectedPage, setPageName } = props;
    const [open, setOpen] = useState(false); // State for modal
    const [selectedIcon, setSelectedIcon] = useState(awsicon);
    const [cloudIcons, setCloudIcons] = useState({});
    const { selectedIconcloud, setSelectedIconcloud } = props;
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [input, setInput] = useState(props?.value ?? '');
    const [loadingicon, setLoadingicon] = useState(false)
    const [toggleState, setToggleState] = React.useState({ toggle: true });
    


    const fetchConfigData = async (configType) => {
        if (isDataLoaded) return;
        setLoadingicon(true)
        try {
            const response = await exchange(
                `${API_BASE_URL}/config/${configType}`,
                "GET"
            );
            const doubleEncodedData = response.body;
            const data = JSON.parse(JSON.parse(doubleEncodedData));
            console.log("..............data", data)
            console.log(data.supported_clouds[0].cloud, "cloud1")
            console.log(data.supported_clouds[1].cloud, "cloud2")
            const supportedClouds = data.supported_clouds
                .filter(cloud => cloud.cloud === 'aws' || cloud.cloud === 'azure')
                .map(cloud => cloud.cloud);

            setCloudIcons(supportedClouds);
            setIsDataLoaded(true);
            setLoadingicon(false)

        } catch (error) {
            console.error("Error fetching data:", error);
            // toast.error("Failed to load cloud configurations.");
        } finally {
            setLoadingicon(false)
        }
    }

    useEffect(() => {
        // Only fetch data if not loaded
        if (!isDataLoaded) {
            fetchConfigData("tenant_config");
        }
    }, [isDataLoaded]);

    const handleClickOpen = () => {
        setOpen(true);
        // fetchConfigData("tenant_config");
        if (!isDataLoaded) {
            setLoadingicon(true); // Show loading spinner while fetching
            // fetchConfigData("tenant_config");
        }
    };

    // Close the modal
    const handleClose = () => {
        setOpen(false);
    };

    // Handle icon selection in the modal
    const handleIconSelect = (cloud_icon, icon) => {
        setSelectedIcon(icon);
        if (cloud_icon == "AWS") {
            setSelectedIconcloud("AWS")
            console.log(setSelectedIcon)
        }
        else if (cloud_icon == "AZURE") {
            setSelectedIconcloud("AZURE")
        }   // Set the selected icon
        setInput(''); // Reset the input if needed, or keep it depending on your use case
        setOpen(false); // Close the modal after selecting an icon
    };

    const handleSendButtonClick = () => {
        if (!llmvaluecheck()) {
            props.onSendButtonClick(input);
            setInput("");
        }
    };
    const llmvaluecheck = () => {
        const llmId = localStorage.getItem("llm-id")
        if (!llmId) {
            toast.error('Please select a Model to proceed', {
                onClose: () => {
                    setToggleState((prevState) => ({ ...prevState, toggle: false }));
                },
            });
            return true;
        }
        return false

    }

    return (
        <div style={{ marginTop: '10px' }}>

            <Paper
                component="form" fullWidth variant="outlined"
                sx={{ ml: "130px", mr: "95px", mt: "0px", mb: "50px", p: '2px 4px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                onSubmit={(e) => {
                    e.preventDefault();
                    if (input.length > 0) {
                        handleSendButtonClick();
                    }
                }}
            >

                <InputBase

                    sx={{ ml: 1, flex: 1, width: 'auto', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    placeholder="Ask me something"
                    inputProps={{ 'aria-label': 'Ask me something' }}
                    value={input}
                    onChange={e => setInput(e.target.value)}
                    disabled={props.inputDisabled}
                    multiline
                    maxRows={3}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey && input.length > 0) {  // Check if Enter is pressed and input is not empty
                            e.preventDefault();  // Prevent the default behavior (like form submission)
                            handleSendButtonClick();  // Trigger the send action
                        }
                        else if (e.key === 'Enter' && e.shiftKey) {
                            return;
                        }
                    }}
                    startAdornment={
                        selectedPage === 'Agent' && ( // Check if selectedPage is 'Agent'
                            <>
                                {/* AWS Icon inside InputBase */}
                                <img
                                    src={selectedIcon}
                                    alt="Selected Icon"
                                    style={{ width: 24, height: 24, marginRight: '8px', cursor: 'pointer' }}
                                    onClick={handleClickOpen} // Open modal on click
                                />
                            </>
                        )
                    }

                // disabled={props.loading}

                />
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton
                    color="primary"
                    sx={{ p: '10px' }}
                    aria-label="send"
                    onClick={handleSendButtonClick}
                    disabled={props.inputDisabled || input.length == 0}
                >
                    <SendIcon />

                </IconButton>
                {/* <button onClick={()=>fetchConfigData('tenant_config')}>Click me</button> */}

                {selectedPage !== 'Agent' && (
                    <IconButton
                        color="primary"
                        onClick={() => { props.toggleDrawer(); setInput(""); }}
                        // onClick={()=>{llmvaluecheck()}}
                        disabled={props.disabled}
                        sx={{ marginLeft: '-6px' }}
                        aria-label="preferences"
                    >
                        <SettingsIcon />
                    </IconButton>
                )}
            </Paper>
            {selectedPage !== 'Agent' && (
                <Typography variant="body2" color="text.secondary">
                    {props.cloud && props.llmName ? (
                        <span style={{ fontWeight: "bold" }}>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ ml: "130px", mr: "95px", mt: "20px", mb: "0px", p: '2px 4px', display: 'flex', alignItems: 'center', marginTop: '-46px' }}
                            >
                                Cloud: {props.cloud.toUpperCase()}
                                {", "}
                                Model: {props.llmName}
                            </Typography>
                        </span>
                    ) : (
                        <span style={{ fontWeight: "bold" }}></span>
                    )}
                </Typography>

            )}

            {props.loading && <LinearProgress />}
            <div className='classCloudprovider' style={{ marginRight: '15%', marginTop: '23%' }}>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Select Cloud Provider</DialogTitle>
                    <DialogContent>
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' ,gap:'30%'}}>
                            {loadingicon ? (
                                <CircularProgress size="30px" />
                            ) : (
                                <>
                                    {cloudIcons[1] === 'azure' && (
                                        <img
                                            src={azureicon}
                                            alt="Azure"
                                            style={{ width: 40, height: 40, cursor: 'pointer' }}
                                            onClick={() => handleIconSelect('AZURE', azureicon)}
                                        />
                                    )}
                                    {cloudIcons[0] === 'aws' && (
                                        <img
                                            src={awsicon}
                                            alt="AWS"
                                            style={{ width: 40, height: 40, cursor: 'pointer' }}
                                            onClick={() => handleIconSelect('AWS', awsicon)}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        </div>



    );
}
